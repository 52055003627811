import { IonCard, IonGrid,IonCol,IonRow,IonCardContent, IonItem, IonSegment, IonSegmentButton, IonInput, IonButton, IonNote, IonLabel, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import ccm_map from '../assets/ccm_map.png';
import { useState } from 'react';
export default function Welcome(props) {


const [segment, setSegment] = useState('login')


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
       

        <IonCard>     
    <IonCardContent color="danger">
            
            
            <b>Zutritt verweigert.</b> Sie müssen eingeloggt sein, um diese Seite sehen zu können.
            
            </IonCardContent>

        </IonCard>        
       
      </IonContent>
    </IonPage>
  );
};