import { IonContent, IonList, IonIcon, IonLabel, IonItem, IonCard, IonCardHeader, IonHeader, IonPage, IonTitle, IonToolbar, IonCardTitle, IonCardContent } from '@ionic/react';
import './Home.css';
import readerIcon from '../assets/reader.svg'
const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Willkommen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Willkommen</IonTitle>
          </IonToolbar>
        </IonHeader>
     <IonCard>
    <IonCardHeader>
      <IonCardTitle>SAINT Premiumbestellapp</IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
    Unser Premium Patientenbestellapp ist ab sofort für Sie verfügbar.
Wem dient diese App und was kann Sie?
Ihren Patienten um bereits vor dem Krankenhausaufenthalt Ihr Wunschessen von zu Hause aus bestellen.
Für Ihr Haus als Imageträger und mit optional erweiterbaren Funktionen zb. Allgemeine Infos über Ihr Haus inkl. Ihren Kliniken Spezialisten und News
Ihre Patienten können über die App das Premium Segment an Menüs bestellen und diese bequem online bezahlen, ohne dass Sie damit Verwaltungsgebühren haben.

Das ist die neuste Kreation und Innovation der Ideenschmiede der Firma solufind.
Scrollen sie durch und kontaktieren sie uns bei Interesse.
Wir freuen uns auf Ihre Kontaktaufnahme.
<a href="mailto:info@solufind.de">info@solufind.de</a>
Homepage: <a href="www.solufind.de">solufind.de</a>
    </IonCardContent>
     </IonCard>
    <IonList>
          <IonItem button routerLink="/order">
            <IonIcon slot="start" icon={readerIcon}></IonIcon>
            <IonLabel>
            {/* <Link to="/order">Menü-Bestellungen</Link> */}
            Menü-Bestellungen
            </IonLabel>
          </IonItem>
       
    </IonList>
      </IonContent>
    </IonPage>
  );
};
export default Home;

