import { IonCard, IonIcon, IonTitle, IonToast, IonFooter,IonItem, IonLabel,  IonMenu,  IonToolbar,  setupIonicReact } from '@ionic/react';
import { IonContent } from '@ionic/react';
import {useEffect, useState} from 'react';

import infoIcon from './assets/information-circle.svg'

import homeIcon from './assets/home.svg'
import './App.css';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


import cfm from './assets/solufind.png'





setupIonicReact();

export default function SidebarLeft() {

const [toast, setToast] = useState(false);
const [difference, setDifference] = useState(null);
const version = '1.0.3'

const fetchVersion = async () => {
  var requestOptions = {
    method: 'GET',
  };

  await fetch("https://web1.saint-online.de/funktionen/api/app/version_json.php", requestOptions)
    .then(response => response.json())
    .then(async (result) => {

      if (result.data !== version) {         
        setDifference(result.data)
        setToast(true)
      }
    })
    .catch(error => console.log('error', error));
}

useEffect( () => { 

  fetchVersion();
  
},[])

  return(
    
<IonMenu side="start" contentId="main" type="overlay">
       
         <IonContent>
    <IonItem color="secondary">
    </IonItem>
    <IonItem lines="none">
    <IonCard id="cfmcard"><img src={cfm} alt="CFM LOGO" id="cfm"/></IonCard>

    </IonItem>
    <IonItem button routerLink="/home">
            <IonIcon slot="start" icon={homeIcon}></IonIcon>
            <IonLabel>
            {/* <Link to="/home">Startseite</Link> */}
            Startseite
            </IonLabel>
          </IonItem>
        
          {/* <IonItem button>
            <IonIcon slot="start" icon={trailIcon}></IonIcon>
            <IonLabel>
            Wegweiser
            </IonLabel>
          </IonItem> */}

        
          <IonItem button routerLink="/info">
            <IonIcon slot="start" icon={infoIcon}></IonIcon>
            <IonLabel>
            Informationen zum Haus

            </IonLabel>
          </IonItem>

          <IonToast
        isOpen={toast}
        color="dark"
        onDidDismiss={() => setToast(false)}
        message={`Für diese App ist ein Update verfügbar ( ${version} -> ${difference} )`}
        duration={2000}
      />
          
    </IonContent>
    <IonFooter>
            <IonToolbar>
              <IonTitle><h6>version {version}</h6></IonTitle>
            </IonToolbar>
          </IonFooter>
      </IonMenu>)}

