import { IonCard, IonGrid,IonCol,IonRow,IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import ccm_map from '../assets/ccm_map.png';
export default function Info(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Campus CCM</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Campus CCM</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard  class="ion-text-center"><img id="qrcode" src={ccm_map} alt="QR CODE"/></IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Cafeteria</IonCardTitle>
            <IonCardSubtitle>Sie finden unsere Cafeteria im Erdgeschoss des Bettenhauses.</IonCardSubtitle>
            <IonCardContent>
            <IonCardSubtitle>Öffnungszeiten</IonCardSubtitle>
<IonGrid>
    <IonRow>
    <IonCol>Montag – Freitag</IonCol>
    <IonCol>7:30 – 18:00 Uhr</IonCol>
    </IonRow>
    <IonRow>
    <IonCol>Sa, So, Feiertage</IonCol>
    <IonCol>10:00 – 17:00 Uhr</IonCol>
    </IonRow>
</IonGrid>
            </IonCardContent>
          </IonCardHeader>
          </IonCard>
      </IonContent>
    </IonPage>
  );
};