import { IonCard, IonCardContent, IonItem, IonToast,IonSegment, IonSegmentButton, IonInput, IonButton,IonLabel, IonCardHeader, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import { useEffect, useState } from 'react';
import { Storage } from '@capacitor/storage';
import { useHistory } from 'react-router-dom';




export default function Welcome(props) {


const [segment, setSegment] = useState('login')
const [loginUser, setLoginUser] = useState('');
const [regVorname, setRegVorname] = useState('');
const [regName, setRegName] = useState('');
const [regAdr, setRegAdr] = useState('');
const [regEmail, setRegEmail] = useState('');
const [regPass, setRegPass] = useState('');
const [regUser, setRegUser] = useState('');
const [toast, setToast] = useState(false);
const [regExist  , setRegExist] = useState(false);
const [regSuccess, setRegSuccess] = useState(false);

const [loginPass, setLoginPass] = useState('');
const [loginTry, setLoginTry] = useState(false);

const history = useHistory();

useEffect( () => {
  const checkToken = async () => {
    const { value } = await Storage.get({ key: 'token' })
    if (!value) { console.log("checktoken", value); return null }
    else return value;
  }

  checkToken()
  .then(result => {
    // console.log("checktokenresult", result)
    if (result !== null) {

      history.push('/home')

    }

}, [])})

useEffect( () => {
    if (loginTry === true)
    {
            fetchLogin();
    }
    else setLoginTry(false)

}, [loginTry])

const register = async () => { 

  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
      "data": {
        "login": regUser,
        "userFirstName": regVorname,
        "userLastName": regName,
        "password": regPass,

      }
    });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };
  
  
  
  await fetch("https://backend.helppilot.de/user.php", requestOptions)
    .then(response => response.json()) 
    .then(async (result) => {
      // console.log(result)

      if (result.status === 200) {

     
        setRegSuccess(true);
  
    }
    else

    {
      setRegExist(true);
    }})

    .catch(error => console.log('error', error))
    .finally(()=>{
      setLoginTry(false);
      })


}

const fetchLogin = () => { 

    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
    "data": {
      "login": loginUser,
      "password": loginPass
    }
  });

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
};



fetch("https://backend.helppilot.de/login.php", requestOptions)
  .then(response => response.json()) 
  .then(async (result) => {
    console.log(result)
    if (result.status === 200) {
    await Storage.set(
      {
        key: 'token',
        value: JSON.stringify(result.data.accesstoken)
      })
    
      props.passToken(true)
      history.push('/home')
     
        }
        else

        {
          setToast(true);
        }


  })
  .catch(error => console.log('error', error))
  .finally(()=>{setLoginTry(false); })
  
}


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>
       

        <IonCard>     
    {/* <IonCardContent>            
            
            Bitte nutzen Sie das nachfolgende Formular zur Anmeldung. Geben Sie dafür bitte ihren Benutzernamen und das Passwort ein. Beide Informationen wurden Ihnen vorab per eMail bereitgestellt.
            
            </IonCardContent> */}

        </IonCard>
        <IonCard>
        <IonCardHeader>
    </IonCardHeader>
    <IonCardContent>
            
          
            
            
            <IonSegment value="login" onClick={e => setSegment(e.target.value)}>
          <IonSegmentButton value="login">
            <IonLabel>Login</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="register">
            <IonLabel>Registrierung</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        { (segment && (segment === 'login')) ? <><IonItem>         
            <IonLabel position="floating">
                Benutzername
            </IonLabel>
            <IonInput placeholder="Name" onIonChange={e=> setLoginUser(e.target.value)} value={loginUser}></IonInput>
            </IonItem>
            <IonItem>
            <IonLabel position="floating">
                Passwort
            </IonLabel>
            <IonInput placeholder="Passwort"  type="password" onIonChange={e=> setLoginPass(e.target.value)} value={loginPass}></IonInput>
            </IonItem>
            <IonButton expand="full" onClick={()=> setLoginTry(true)}>Anmelden</IonButton>
            </> : 
             (segment && (segment === 'register')) ?
            <>
            <IonItem>         
            <IonLabel position="floating">
                Benutzername
            </IonLabel>
            <IonInput placeholder="Benutzername"  onIonChange={e=> setRegUser(e.target.value)} value={regUser}></IonInput>
            </IonItem>
            <IonItem>         
            <IonLabel position="floating">
                Vorname
            </IonLabel>
            <IonInput placeholder="Vorname"  onIonChange={e=> setRegVorname(e.target.value)} value={regVorname}></IonInput>
            </IonItem>
            <IonItem>
            <IonLabel position="floating">
                Nachname
            </IonLabel>
            <IonInput placeholder="Nachname"  onIonChange={e=> setRegName(e.target.value)} value={regName}></IonInput>
            </IonItem>
            <IonItem>
            <IonLabel position="floating">
                Adresse
            </IonLabel>
            <IonInput placeholder="0800 Musterhausen"  onIonChange={e=> setRegAdr(e.target.value)} value={regAdr}></IonInput>
            </IonItem>
            <IonItem>
            <IonLabel position="floating">
                E-Mail-Adresse
            </IonLabel>
            <IonInput placeholder="max@mustermann.de"  onIonChange={e=> setRegEmail(e.target.value)} value={regEmail}></IonInput>
            </IonItem>
            <IonItem>
            <IonLabel position="floating">
                Passwort
            </IonLabel>
            <IonInput placeholder=""  onIonChange={e=> setRegPass(e.target.value)} value={regPass}></IonInput>
            </IonItem>

            <IonButton expand="full" onClick={ () => register() }>Registrieren</IonButton>
            </>
            : ''
             }

            </IonCardContent>

        </IonCard>

        <IonToast
        isOpen={toast}
        color="danger"
        onDidDismiss={() => setToast(false)}
        message="Benutzerdaten falsch. Bitte prüfen."
        duration={2000}
      />

<IonToast
        isOpen={regExist}
        color="danger"
        onDidDismiss={() => setRegExist(false)}
        message="Dieser Name existiert bereits."
        duration={2000}
      />
        
        <IonToast
        isOpen={regSuccess}
        color="success"
        onDidDismiss={() => {setRegSuccess(false); setSegment('login'); history.push('/')} }
        message="Der Benutzer wurde angelegt. Bitte melden Sie sich an."
        duration={3000}
      />
       
      </IonContent>
    </IonPage>
  );
};
