import {useEffect, useState} from 'react';
import { Storage } from '@capacitor/storage';


import {
  IonCard,
  IonItem,
  IonNote,
  IonContent,
  IonGrid,
  IonCardContent,
  IonRow,
  IonCol,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCardTitle,
  IonLabel,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./Qr.css";
import 'chart.js/auto';
import { Doughnut } from "react-chartjs-2";
export default function Nahrung(props) {


  const [cartData, setCartData] = useState(null)
  const [dates, setDates] = useState(null)
  const [kcal, setKcal] = useState(0)
  const [kh, setKh] = useState(0)
  const [proteine, setProteine] = useState(0)
  const [fett, setFett] = useState(0)
  const [salz, setSalz] = useState(0)
  const [gesFett, setGesFett] = useState(0)
  const [zucker, setZucker] = useState(0)




  const checkStorage = async () => {

    const {value} = await Storage.get({ key: 'cart' })
    // console.log("value", value)
    if (value !== null) {setCartData(JSON.parse(value))}
    
    }

  useEffect(()=>{

    checkStorage()

  },[])

  useEffect(() => {

    if (cartData !== null) {
      // console.log("cartData", cartData)
      let tempData = cartData;
      let temp = [];
      tempData.map(e => temp.push(e.datum))
      // console.log("TEMP", Array.from(new Set(temp)))
      setDates(Array.from(new Set(temp)))

    }

  }, [cartData,])

  const 
  getKcal = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push( (((komp.kcal)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

setKcal(sumWithInitial)


      
    }

  }

  const getKh = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.kh) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

 setKh(sumWithInitial)

      
    }

  }

  const getProteine = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.eiweiss) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

setProteine(sumWithInitial)


      
    }

  }

  
  const getGesFett = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {

        if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.gesfett) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))


      const initialValue = 0;
const sumWithInitial = tempSumme.reduce(
  (previousValue, currentValue) => previousValue + currentValue,
  initialValue)

  setGesFett(sumWithInitial)


}
    }
  
    
    const getFett = () => {

      if (cartData !== null) {
  
        let tempCart = cartData;
  
        let tempSumme = [];
        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.fett) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
  const sumWithInitial = tempSumme.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue)
  
    setFett(sumWithInitial)
  
  
        
      }
  
    }

    const getZucker = () => {

      if (cartData !== null) {
  
        let tempCart = cartData;
  
        let tempSumme = [];
        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.zucker) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
  const sumWithInitial = tempSumme.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    initialValue)
  
    setZucker(sumWithInitial)
  
  
        
      }
  
    }

    const getSalz = (solodate) => {

      if (cartData !== null) {
  
        let tempCart = cartData;

        
        console.log(tempCart = tempCart.filter(e => e.datum === '20220419'))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.salz) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
        const sumWithInitial = tempSumme.reduce( (previousValue, currentValue) => previousValue + currentValue, initialValue )
  
         setSalz(sumWithInitial)
  
  
        
      }
  
    }

    function formatDate(string) {

      let year = (string.substr(0, 4));
      let month = (string.substr(4, 2));
      let day = (string.substr(6, 2));
  
      const date = new Date(year, month, day - 2);
  
      let wochentag =
        date.toLocaleDateString('de-DE', {
          weekday: 'long',
        })
  
      return `${wochentag} - ${day}.${month}.${year}`
    }

    function getDataZusatz(datum, zusatz) {

      console.log("Datum",datum)
      console.log("Zusatz",zusatz)


      if (cartData !== null) {
  
        let tempCart = cartData;

        console.log(tempCart = tempCart.filter(e => e.datum === datum))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { console.log("DUDE",komp[zusatz]); tempSumme.push(   ((  (  (komp[zusatz]) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
        const sumWithInitial = tempSumme.reduce( (previousValue, currentValue) => previousValue + currentValue, initialValue )
  
        //  console.log("d", sumWithInitial)

        console.log("SUMME", sumWithInitial)

        return sumWithInitial;

    }}

    function getDataEiweiss(datum) {

      console.log("Datum Eiweiss",datum)

      if (cartData !== null) {
  
        let tempCart = cartData;

        
        console.log(tempCart = tempCart.filter(e => e.datum === datum))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.eiweiss) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
        const sumWithInitial = tempSumme.reduce( (previousValue, currentValue) => previousValue + currentValue, initialValue )
  
        //  console.log("d", sumWithInitial)

         const data = {
          labels: ["Proteine", "verfügbarer Tagesbedarf"],
          datasets: [
            {
              label: "Proteine",
              backgroundColor: ["yellow", '#C0C0C0'],
              borderWidth: 1,
              data: [sumWithInitial, ( 55.5 - sumWithInitial)],
            },
          ],
        }

        return data;

    }}

       function getDataKH(datum) {

      console.log("Datum Eiweiss",datum)

      if (cartData !== null) {
  
        let tempCart = cartData;

        
        console.log(tempCart = tempCart.filter(e => e.datum === datum))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.kh) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
        const sumWithInitial = tempSumme.reduce( (previousValue, currentValue) => previousValue + currentValue, initialValue )
  
        //  console.log("d", sumWithInitial)

         const data = {
          labels: ["Kohlenhydrate", "verfügbarer Tagesbedarf"],
          datasets: [
            {
              label: "Kalorien",
              backgroundColor: ["lightblue", '#C0C0C0'],
              borderWidth: 1,
              data: [sumWithInitial, 
                ( 293.3 - sumWithInitial)],
            },
          ],
        };

        return data;

    }}

     function getDataFett(datum) {

      console.log("Datum Eiweiss",datum)

      if (cartData !== null) {
  
        let tempCart = cartData;

        
        console.log(tempCart = tempCart.filter(e => e.datum === datum))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((  (  (komp.fett) /1000)/100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
        const sumWithInitial = tempSumme.reduce( (previousValue, currentValue) => previousValue + currentValue, initialValue )
  
        //  console.log("d", sumWithInitial)

         const data = {
          labels: ["Überschreitung", "Fett"],
          datasets: [
            {
              label: "Fett",
              backgroundColor: ["red", 'orange'],
              borderWidth: 1,
              data: [sumWithInitial, ( 92.5 - sumWithInitial)],
            },
          ],
        }

        return data;

    }}

    function getDataKcal(datum) {

      console.log("Datum Eiweiss",datum)

      if (cartData !== null) {
  
        let tempCart = cartData;

        
        console.log(tempCart = tempCart.filter(e => e.datum === datum))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((   (komp.kcal) /100)*komp.g_pro_portion)*komp.menge ) }})))
  
  
        const initialValue = 0;
        const sumWithInitial = tempSumme.reduce( (previousValue, currentValue) => previousValue + currentValue, initialValue )
  
         const data = {
          labels: ["KCal", "verfügbarer Tagesbedarf"],
    datasets: [
      {
        label: "Kalorien",
        backgroundColor: ["lightgreen", '#C0C0C0'],
        borderWidth: 1,
        data: [sumWithInitial, ( 2550 - sumWithInitial)],
      },
    ],
        }

        return data;

    }}

    function checkData(datum) {


      if (cartData !== null) {
  
        let tempCart = cartData;
        
        console.log(tempCart = tempCart.filter(e => e.datum === datum))
        
        let tempSumme = [];

        tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
  
          if (komp.menge > 0) { tempSumme.push(   ((   (komp.kcal) /100)*komp.g_pro_portion)*komp.menge ) }})))
  
        if (tempSumme.length > 0) { return true} else {return false}

    }}


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Nährwerte</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Nährwerte</IonTitle>
          </IonToolbar>
        </IonHeader>

        {dates && dates.sort().map(einzeldatum => {

return (

  <>
    <IonCard key={`${einzeldatum}_`}>
    <IonCardHeader>
      <IonCardTitle>
    
      <IonLabel>Übersicht {formatDate(einzeldatum)}</IonLabel>
      </IonCardTitle>
</IonCardHeader>
          
      <IonCardContent> 
        { (checkData(einzeldatum) === true) ?
        <>
        <IonCardSubtitle>Tagesbedarfe</IonCardSubtitle>
      <IonGrid>
            <IonRow>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={getDataKcal(einzeldatum)} /></IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={getDataKH(einzeldatum)} /></IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={getDataEiweiss(einzeldatum)} /></IonCol>
            <IonCol size-xs="6" size-sm="6" size-md="3"> <Doughnut data={getDataFett(einzeldatum)} /></IonCol>
            </IonRow>
          </IonGrid>
          <IonCardTitle>Werte</IonCardTitle>
            <IonGrid>
              <IonItem>
                <IonLabel color="secondary"><h3>KCal</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'kcal')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>            
              <IonItem>
                <IonLabel color="secondary"><h3>KJ</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'kcal')*4.1868).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
              <IonItem>
                <IonLabel color="secondary"><h3>Fett</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'fett')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
                            <IonItem>
                <IonLabel color="secondary"><h3>davon ges. Fettsäuren</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'gesfett')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
              <IonItem>
                <IonLabel color="secondary"><h3>Kohlenhydrate</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'kh')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
                            <IonItem>
                <IonLabel color="secondary"><h3>davon Zucker</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'zucker')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
              <IonItem>
                <IonLabel color="secondary"><h3>Eiweiß</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'eiweiss')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
              <IonItem>
                <IonLabel color="secondary"><h3>Salz</h3></IonLabel>
                <IonNote color="primary" slot="end"><h3>{parseFloat(getDataZusatz(einzeldatum,'salz')).toLocaleString('de', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}</h3></IonNote>
              </IonItem>
            
            </IonGrid></> : ' Für diesen Tag wurde kein Essen gewählt.' }
      </IonCardContent>
        
      </IonCard>
      
  </>

)
})}

      </IonContent>
    </IonPage>
  );
}