import { IonCard, IonCardHeader, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import checkmark from '../assets/qrcode.png';
export default function Order(props) {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>QR</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">QR</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard  class="ion-text-center"><img id="qrcode" src={checkmark} alt="QR CODE"/></IonCard>
        <IonCard>
          <IonCardHeader>
            <IonTitle>herbert.mueller</IonTitle>
          </IonCardHeader>
          </IonCard>
      </IonContent>
    </IonPage>
  );
};