import { IonNote, IonCardTitle, IonChip, IonButton, IonModal, IonGrid, IonIcon, IonList, IonRow, IonCol, IonText, IonFooter, IonPage, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonTitle, IonToolbar, IonCardHeader, IonCardSubtitle, IonCardContent } from '@ionic/react';
import { useEffect, useState } from 'react'
import './Warenkorb.css';
import { Storage } from '@capacitor/storage';
import restaurant from "../assets/restaurant.svg"
import info from "../assets/information-circle.svg";




export default function Warenkorb(props) {

  const [cartData, setCartData] = useState(null)
  const [summe, setSumme] = useState(0)
  const [dates, setDates] = useState(null)
  const [selectedFood, setSelectedFood] = useState([])
  const [selectedDatum, setSelectedDatum] = useState(null)
  const [nutritionElement, setNutritionElement] = useState(null)
  const [nutritionModal, setNutritionModal] = useState(false)

  const [allergene, setAllergene] = useState([])
  const [modalAllergene, setModalAllergene] = useState(false)
  const [allergenListe, setAllergenListe] = useState(null)
  const [today, setToday] = useState(null)
  const [hideClasses, setHideClasses] = useState([])



  const getCart = async () => {
    const { value } = await Storage.get({ key: 'cart' })
    if (value !== null) {

      let temp = JSON.parse(value);

      setCartData(temp)

    }

  }

  const fetchAllergene = async () => {

    var requestOptions = {
      method: 'GET',
    };

    await fetch("https://web1.saint-online.de/funktionen/api/app/allergene_json.php", requestOptions)
      .then(response => response.json())
      .then(async (result) => {
       setAllergenListe(result.data[0])

      })
      .catch(error => console.log('error', error));


  }

  useEffect(() => {

    if (cartData !== null) {
      // console.log("cartData", cartData)
      let tempData = cartData;
      let temp = [];
      tempData.map(e => temp.push(e.datum))
      // console.log("TEMP", Array.from(new Set(temp)))
      setDates(Array.from(new Set(temp)))
      getSumme();

    }

  }, [cartData,])

  function showAllergene(zeugs) {

    // console.log("ZEUGS",zeugs)
    let elemente=[];

    // console.log(zeugs.toString())

    if (zeugs.toString().search(",") !== -1) {

      
      elemente = zeugs.toString().split(',');
      // console.log(elemente)

    }

  else {

    elemente.push(zeugs.toString());      
    // console.log(elemente)
  }
  
    
    setAllergene(elemente)
    setModalAllergene(true)
    
  }

  const getSumme = () => {

    if (cartData !== null) {

      let tempCart = cartData;

      let tempSumme = [];
      tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => { tempSumme.push( (typeof komp.menge === 'number' ? komp.menge : 0 ) * (typeof komp.preis === 'number' ? komp.preis : 0 )) })))


      // console.log("SUMME", tempSumme)


      const initialValue = 0;
      const sumWithInitial = tempSumme.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
      setSumme(sumWithInitial)
    }

  }

  useEffect(() => {


    //  dates && console.log(dates)


  }, [dates])

  useEffect( () => {

      
    selectedDatum && console.log("warenkorb datum", selectedDatum)
    

  },[selectedDatum])
    

  function getNow(){
    let event = new Date().toISOString().split('T')[0].split('-');
    let datenew = event[0]+event[1]+event[2];
    // console.log(datenew)
    setToday(datenew)
    }

  function formatDate(string) {

    let year = (string.substr(0, 4));
    let month = (string.substr(4, 2));
    let day = (string.substr(6, 2));

    const date = new Date(year, month, day - 3);

    let wochentag =
      date.toLocaleDateString('de-DE', {
        weekday: 'long',
      })

    return `${wochentag} - ${day}.${month}.${year}`
  }

  async function addToCart(type, id, auswahl,datum) {

    console.log( type ) ;
    console.log( id ) ;
    console.log( auswahl ) ;
    console.log( datum ) ;


    (type.toString() === 'plus') ? (auswahl.menge += 1) : (auswahl.menge -= 1)

    


    let tempCart = await Storage.get({ key: 'cart' });
    tempCart = JSON.parse(tempCart.value);

    let position;

  
    console.log("warenkorb tempCart", tempCart)
    tempCart.filter((data, dataindex) => data.menues.filter((menu, menuindex) => menu.komponenten.forEach((artikel, artikelindex) => { if ((artikel.id === id)  && (data.datum === datum)) { position = { "d": dataindex, "m": menuindex, "a": artikelindex } } })))
    console.log("AUSWAHL INDEX",position)

    tempCart[position.d].menues[position.m].komponenten[position.a] = auswahl;


    // console.log(`EINGETRAGEN bei ${position}?`,tempCart)
    await Storage.set({ key: 'cart', value: JSON.stringify(tempCart) })

  }

  useEffect(() => {

    fetchAllergene();
    getCart();
    getSumme();
    getNow();

  }, [])

  function showElements(datum){

      console.log("HIDE", hideClasses)
  let tempDatum = hideClasses;
  if (tempDatum.includes(datum)) { 
    console.log("VORHER", tempDatum)
    tempDatum.splice(tempDatum.indexOf(datum),1)
    console.log("NACHHER", tempDatum)

    setHideClasses( [...hideClasses, tempDatum])
    console.log(hideClasses)


    let temp = document.getElementsByClassName(datum)
    console.log("temp",temp)
    for (let i=0; i<temp.length; i++) { console.log(i, temp[i].style.display); temp[i].style.display='none'}

  } else { 
    setHideClasses( [...hideClasses, datum])

    let temp = document.getElementsByClassName(datum)
    console.log("temp",temp)
    for (let i=0; i<temp.length; i++) { console.log(i, temp[i].style.display); temp[i].style.display='block'}
  }

  


  }
  function showNutrition(element) {

    // console.log("showNutrition ELEMENT", element)
    setNutritionElement(element);
    setNutritionModal(true);
  }
function checkHide(datum) {


  let tempCart;
  cartData && (tempCart = cartData)
  tempCart = tempCart.filter(date => date.datum === datum)
  let checkInDatum= tempCart;
  let checkResultInDatum=false;

console.log("CHECKDATUM",checkInDatum.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => { if (komp.menge > 0) { checkResultInDatum=true; } }))))
console.log(checkResultInDatum);

if (checkResultInDatum && (datum < today)) { 
  console.log("check true")
  return <IonChip outline onclick={() => showElements(datum)}>{(hideClasses && hideClasses.includes(datum)) ? "ausblenden" : "Hier klicken, um Bestellungen anzuzeigen"}</IonChip>
}
}

  function showMeal(datum, mahlzeit) {

    let tempCart;
    cartData && (tempCart = cartData)
    tempCart = tempCart.filter(date => date.datum === datum)
    let checkInDatum= tempCart;
    tempCart = tempCart.filter(e => e.mahlzeit === mahlzeit)
    let countEmpty = [];
    tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => { if (komp.menge > 0) { countEmpty.push(komp) } })))
    // console.log(tempCart)
   let checkResultInDatum=false;

    // console.log("CHECKDATUM",checkInDatum.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => { if (komp.menge > 0) { checkResultInDatum=true; } }))))
// console.log(checkResultInDatum);


    if (countEmpty.length > 0) {
      return (

        <>
        <div className={`${datum}`} style={{ display: (datum < today ) ? "none" : ""}}>

        <IonCard key={`${datum}_${mahlzeit}`}>
          <IonCardHeader>
            <IonCardTitle><div>{mahlzeit === 1 ? 'Frühstück' : mahlzeit === 2 ? 'Mittagessen' : 'Abendessen'}</div></IonCardTitle>
            <IonCardSubtitle>{formatDate(datum.toString())} </IonCardSubtitle>
          </IonCardHeader>

          {tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
            if (komp.menge > 0) {



              return (

                <>
                
                <IonItem 
                  className="ion-text-wrap" lines="none" key={`${komp.id}_${komp.bezeichnung}}`}>
                    <IonLabel className="ion-text-wrap" lines="none"><h2>{komp.bezeichnung}<IonIcon slot="start"  onClick={() => showAllergene(komp.zusatzstoffe)} color="primary" icon={info}></IonIcon>{ ( (komp.code !== '') && (komp.code.length > 0 )) ? <img src={'https://web1.saint-online.de/test/svg_'+komp.code+'.png'} alt="mealcode" /> : ''}</h2></IonLabel>

                  

                    <IonNote text-wrap lines="none" slot="start">{(komp.img) ? <img style={{ height: '30px', width: '30px', borderRadius: '5px', alignSelf: 'bottom' }} src={komp.img} alt="img" /> : <img src={restaurant} style={{ height: '40px', marginRight: '15px', alignSelf: 'bottom' }} alt="placeholder" />}</IonNote>

                  </IonItem>
                  <IonGrid>
            <IonRow>
              <IonCol  className="alignbottom"><div onClick={() => showNutrition(komp)}><h6>Nährwerte anzeigen <img src={info} alt="info" style={{height: '15px'}}/></h6></div></IonCol>
            </IonRow>
          </IonGrid>

                   
                       
                  <IonGrid style={{background: 'lightgrey'}} color="light">
                  <IonRow>
                    <IonCol  className="aligncenter" size="6" style={{alignVertical: 'middle'}} >                      
                                            {komp.menge === 0 ?
                                  <IonButton size="default" disabled={ (today>e.datum) } onClick={() => { setSelectedDatum(e.datum); setSelectedFood(menu.komponenten); addToCart('plus', komp.id, komp, e.datum); props.passProp(true); }}>Bestellen</IonButton> :
                                  (<>
                                    {komp.menge === 0 ? '' : <IonButton  disabled={ (today>e.datum) } color={komp.premium ? 'warning' : 'primary'} onClick={() => {addToCart('minus', komp.id, komp, e.datum); props.passProp(true); getSumme(); }} size="small">–</IonButton>}
                                    
                                    <span className='text'><input type="text" value={komp.menge} /></span>
                                    <IonButton  disabled={ (today>e.datum) } size="small" color={komp.premium ? 'warning' : 'primary'} onClick={() => { addToCart('plus', komp.id, komp, e.datum); props.passProp(true); getSumme(); }}>+</IonButton></>)}</IonCol>
                    <IonCol className=" aligncenter right"><IonText color="primary">{komp.preis === 0 ? '' : "x " + parseFloat(komp.preis).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €"}</IonText></IonCol>
                    <IonCol className=" aligncenter right"><IonText color="primary"><b>{komp.preis === 0 ? '' : (komp.menge * komp.preis).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €"}</b></IonText></IonCol>
                  </IonRow>
                  </IonGrid>
</>
              )
            }
          })))}

          <IonCardContent>
          </IonCardContent>
        </IonCard>
        </div>
        </>
      )
    }
   
 
    
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Menü-Bestellungen</IonTitle>
          </IonToolbar>
        </IonHeader>

        {dates && dates.sort().map(einzeldatum => {

          return (

            <>
              <IonCard key={`${einzeldatum}_`}>
                <IonCardTitle class="datum">{formatDate(einzeldatum)} {checkHide(einzeldatum)}</IonCardTitle>
                {showMeal(einzeldatum, 1)}
                {showMeal(einzeldatum, 2)}
                {showMeal(einzeldatum, 3)}
                </IonCard>
            </>

          )
        })}

      </IonContent>
      <IonFooter>
        <IonToolbar>

          <IonGrid>
            <IonRow>
              <IonCol size="6" className="alignit left" >
                <IonItem style={{ margin: '0', padding: '0' }} lines="none">
                  <IonText color="primary" ><h5><b>Summe :  {parseFloat(summe).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}  €</b></h5></IonText>
                </IonItem>
              </IonCol>
              <IonCol size="6" className="alignit right">
                <IonItem lines="none" text-wrap>
                  <IonButton className="ion-text-wrap orderbutton" size="default" color={summe > 0 ? 'warning' : 'primary'}>{summe > 0 ? 'kostenpflichtig ' : ''}bestellen</IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonToolbar>

        <IonModal
        isOpen={nutritionModal}
        // trigger="trigger-button"
        breakpoints={[0.1, 0.5, 1]}
        initialBreakpoint={0.75}
        onWillDismiss={() => {setNutritionModal(false);  setNutritionElement(null)}}
      >
        <IonContent>
       <IonButton onClick={() => {setNutritionModal(false);  setNutritionElement(null) }} expand="full" color="dark">Schließen</IonButton>

        <IonItem>
         
         <IonCard className="article ion-text-center" style={{ backgroundImage: `url('${info}')`, backgroundSize: 'cover' }}> </IonCard>      
              

         <IonLabel id="Lorem_Ipsum">Nährwert-Angaben:</IonLabel> 
       </IonItem>
         
          <IonList> 
            
          <IonGrid>
            <IonRow>
              <IonCol size="12">  
              <IonItem>
                  <IonLabel color="primary">Kalorien</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.kcal)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g / { nutritionElement && Math.round(parseFloat(((nutritionElement.kcal)/100)*nutritionElement.g_pro_portion)*4.1868).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1})} kJ</h6>
                      
                        </IonNote>
              </IonItem>                       
              <IonItem>
                  <IonLabel color="primary">Fett</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.fett/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">gesättigte Fettsäuren</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.gesfett/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Kohlenhydrate</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.kh/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Zucker</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.zucker/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Eiweiss</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.eiweiss/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Salz</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.salz/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
               </IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
          </IonList>
       <IonButton onClick={() => {setNutritionModal(false);  setNutritionElement(null) }} expand="full" color="dark">Schließen</IonButton>

          </IonContent>
          </IonModal>

          <IonModal
        isOpen={modalAllergene}
        // trigger="trigger-button"
        breakpoints={[0.1, 0.5, 1]}
        initialBreakpoint={0.75}
        onWillDismiss={() => {setModalAllergene(false); }}
      >
        <IonContent>
         <IonButton onClick={() => {setModalAllergene(false); }} expand="full" color="primary">Schließen</IonButton>
        <IonItem>
         
         <IonCard className="article ion-text-center" style={{ backgroundImage: `url('${info}')`, backgroundSize: 'cover' }}> </IonCard>      
              

         <IonLabel id="Lorem_Ipsum" className="ion-text-wrap">Legende zu Allergenen / Zusatzstoffen:</IonLabel> 
       </IonItem>

       <IonGrid>
         <IonRow>
           <IonCol size="12">
             <IonList>
               { (allergene) && allergene.map( e=> { return( 

        
        <><IonItem> 
                 <IonLabel>{allergenListe ? allergenListe[e] : 'Zusatzstoff / Allergen'}</IonLabel>
                 <IonNote style={{width: '40px'}} color="primary" slot="start">{e}</IonNote>
               </IonItem></>

               )})}
               


              
             </IonList>
           </IonCol>
         </IonRow>
       </IonGrid>
       <IonButton onClick={() => {setModalAllergene(false); }} expand="full" color="primary">Schließen</IonButton>

          </IonContent>
          </IonModal>

      </IonFooter>
    </IonPage>
  );
};

