import {
    IonCard,
    IonContent,
    IonGrid,
    IonCardContent,
    IonRow,
    IonCol,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCardTitle,
    IonLabel,
    IonCardHeader,
    IonCardSubtitle,
    IonIcon
  } from "@ionic/react";
  import "./Qr.css";
  import 'chart.js/auto';
  import checkmark from '../assets/checkmark-done-circle-outline.svg';
  export default function Aufenthalt(props) {
 
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Aufenthalt</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader>
            <IonToolbar>
              <IonTitle size="large">Aufenthalt</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonCard> <IonCardHeader>
            <IonCardTitle>
              <IonLabel>Fallnummer 123456789</IonLabel>
            </IonCardTitle>
            <IonCardSubtitle>Tagesbedarfe</IonCardSubtitle>
           </IonCardHeader>
            <IonCardContent>
              <IonCardSubtitle>Aufenthalt</IonCardSubtitle>
              <IonGrid>
                <IonRow>
                <IonCol>von</IonCol>
                <IonCol>bis</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>30.März.2022</IonCol>
                  <IonCol>31.März.2022</IonCol>
                </IonRow>
              </IonGrid>
              <IonCardSubtitle>Zimmer</IonCardSubtitle>
              <IonGrid>
                <IonRow>
                <IonCol>von</IonCol>
                <IonCol>bis</IonCol>
                <IonCol>Campus</IonCol>
                <IonCol>Station</IonCol>
                <IonCol>Zimmer</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>30.März.2022</IonCol>
                  <IonCol>31.März.2022</IonCol>
                  <IonCol>CCM</IonCol>
                  <IonCol>Station 1</IonCol>
                  <IonCol>12345</IonCol>
                </IonRow>
              </IonGrid>
              <IonCardSubtitle>Status</IonCardSubtitle>
              <IonIcon size="large"  src={checkmark}></IonIcon> verifiziert
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }