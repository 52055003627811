import { IonApp, IonToolbar, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuButton, IonPage, IonRouterOutlet, IonSplitPane, IonTitle, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import SidebarRight from './SidebarRight';
import SidebarLeft from './SidebarLeft';
import Home from './pages/Home';
import Qr from './pages/Qr';
import Order from './pages/Order';
import Warenkorb from './pages/Warenkorb';
import Nahrung from './pages/Nahrung';
import Info from './pages/Info';
import personCircle from './assets/person-circle.svg';
import basketIcon from './assets/basket.svg'
import basketEmptyIcon from './assets/basket-outline.svg'
import Badge, { BadgeProps } from '@mui/material/Badge';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Storage } from '@capacitor/storage';
import Aufenthalt from "./pages/Aufenthalt"
import Welcome from "./pages/Welcome"
import Forbidden from "./pages/Forbidden"




setupIonicReact();

const App = (props: any) => {

  const [propi, setPropi] = useState(false);
  const [token, setToken] = useState(false);

  const [cartJSON] = useState([]);
  const [cartLength, setCartLength] = useState(0);



  useEffect(() => {

    // console.log("something happened")

    if (propi === true || token === true) {
      // console.log("hello propi");
      // console.log("token", token)
      

      // getData2();

      checkStorage()
    }
    // else{ console.log("off")}

  }, [propi, token])


  const getTimestamp = async () => {
    const value = await Storage.get({ key: 'timestamp' });
    return value.value
  }


  const setData = async () => {

    var requestOptions = {
      method: 'GET',
    };

    await fetch("https://web1.saint-online.de/funktionen/api/app/bestellapp_json.php", requestOptions)
      .then(response => response.json())
      .then(async (result) => {
        await Storage.set(
          {
            key: 'cart',
            value: JSON.stringify(result.data)
          })

      })
      .catch(error => console.log('error', error));

  }
 

  const checkStorage = async () => {

    const { value } = await Storage.get({ key: 'cart' })


    if (value !== null) {
      // console.log(JSON.parse(value))

      let temp = JSON.parse(value);

      let menge: any = [];
      temp.map((data: any) => data.menues.filter((menu: any) => menu.komponenten.forEach((artikel: any) => { if (artikel.menge > 0) { menge.push(artikel) } })))
      setCartLength(menge.length)
      // console.log(menge)
    }

    setPropi(false)


  };

  useEffect(() => {


    const checkCart = async () => {
      const { value } = await Storage.get({ key: 'cart' })
      if (value === null) { console.log(value); return null }
      else return value;

    }

    const checkToken = async () => {
      const { value } = await Storage.get({ key: 'token' })
      if (!value) { console.log("checktoken", value); return null }
      else return value;
    }

    checkToken()
    .then(result => {
      // console.log("checktokenresult", result)
      if (result !== null) {

        // console.log("token result ist nicht null")

               setToken(true);

      }
      else setToken(false)
    })

    checkCart()
      .then(result => {
        if (result === null) {

          // console.log("cart ist null");
          setData();

        } else { 
          // console.log("nicht null");
        }
      }).finally(() => checkStorage())
      

  }, [])



  useEffect(() => {

    // console.log("cartJSON", JSON.parse(cartJSON).length)   
    // console.log("cart", cartJSON)

    cartJSON && cartJSON.filter((data, dataindex) => console.log(data))
    setCartLength(cartJSON.length)

    setPropi(false)

  }, [cartJSON])


  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: -10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      width: '10px'
    },
  }));

  return (
    <IonApp>
      <IonPage>
        <IonHeader >
          <IonToolbar>

            <IonButtons slot="start">
              <IonMenuButton menu="start" auto-hide="false" color="primary"></IonMenuButton>
            </IonButtons>

            { token && (<IonButtons slot="end">

            <IonButton routerLink="/warenkorb">
                 
                 {/* 
                 {(cartLength && cartLength !== 0) ? <StyledBadge badgeContent={cartLength} anchorOrigin={{
                                     vertical: 'bottom',
                                     horizontal: 'left',
                                   }} color="secondary">
                                     <IonIcon slot="icon-only" src={basketIcon} size="large" color="primary"></IonIcon>
                                   </StyledBadge> : ''
                                   } */}
                 {(cartLength && cartLength !== 0) ? 
                                     <StyledBadge badgeContent={cartLength} anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'left',
                                     }} color="secondary" className="rubberBand"><IonIcon slot="icon-only" src={basketIcon} size="large" color="primary"></IonIcon></StyledBadge>
                                    : <IonIcon slot="icon-only" src={basketEmptyIcon} size="large" color="primary"></IonIcon>
                                   }
                 
                                 </IonButton>
              
              <IonMenuButton menu="end" auto-hide="false">
                <IonButton>

                 <IonIcon slot="icon-only" src={personCircle} size="large" color="primary"></IonIcon>
              </IonButton>
              </IonMenuButton>
            
                 
            </IonButtons>) }

            <IonTitle></IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader>

          </IonHeader>
          {/* <ExploreContainer /> */}


          <IonSplitPane when="(min-width: 2024px)" contentId="main">
            {/*--  the side menu  --*/}
            <SidebarLeft />

            {/*-- the main content --*/}
            <IonPage id="main">

              <IonReactRouter>
                <IonRouterOutlet>
                  <Route exact path="/aufenthalt">
                  { !token ? (<Forbidden/>) : <Aufenthalt />}
                  </Route>
                  <Route exact path="/welcome">
                    <Welcome  passToken={(token: boolean) => setToken(token)} />
                  </Route>
                  <Route exact path="/home">
                    <Home />
                  </Route>
                  <Route exact path="/qr">
                  { !token ? (<Forbidden/>) : <Qr />}
                  </Route>
                  <Route exact path="/info">
                    <Info />
                  </Route>
                  <Route exact path="/warenkorb">
                    { !token ? (<Forbidden/>) : <Warenkorb passProp={(status: boolean) => setPropi(status)} />}
                  </Route>
                  <Route exact path="/nahrung">
                    { !token ? (<Forbidden/>) : <Nahrung />}
                  </Route>
                  <Route exact path="/">
                       {token ? <Redirect to="/home" /> : <Redirect to="/welcome" />}
                  </Route>
                  <Route exact path="/order">
                    { !token ? (<Forbidden/>) : <Order passProp={(status: boolean) => setPropi(status)} />}
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
            </IonPage>

            { token && (<SidebarRight token={token}/>)}



          </IonSplitPane>


        </IonContent>
      </IonPage>
    </IonApp>
  );
};

export default App;
