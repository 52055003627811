import { IonButton, IonIcon, IonItem, IonLabel, IonMenu, setupIonicReact } from '@ionic/react';
import { IonContent } from '@ionic/react';

import './App.css';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import calendarIcon from './assets/calendar.svg'
import informationIcon from './assets/information-circle.svg'
import basketIcon from './assets/basket.svg'
import qrcode from './assets/qr-code-outline.svg'
import nutritionIcon from './assets/nutrition.svg'
import readerIcon from './assets/reader.svg'
import returnIcon  from './assets/return.svg'

import { Storage } from '@capacitor/storage';


setupIonicReact();

export default function SidebarRight(props) {

  const logout = async () => {

    await Storage.remove({ key: 'cart' });
    await Storage.remove({ key: 'token' });

  }

//  console.log(props)

  return(
    <IonMenu side="end" contentId="main" type="overlay">
    <IonContent>
      <IonItem></IonItem>
    {/* <IonItem color="secondary" lines="none"> */}
    <IonButton expand="full" color="secondary" size="large" disabled>Herbert Müller</IonButton>

    {/* </IonItem> */}
    {props.token && (<IonItem button routerLink="/aufenthalt">
        <IonIcon slot="start" src={calendarIcon}></IonIcon>
        <IonLabel>
          Mein Aufenthalt
        </IonLabel>
      </IonItem>)}

      <IonItem button routerLink="/qr">
        <IonIcon slot="start" src={qrcode}></IonIcon>
        <IonLabel>
          QR-Code
        </IonLabel>
      </IonItem>
   
      {props.token && (<><IonItem button routerLink="/nahrung">
            <IonIcon slot="start" icon={nutritionIcon}></IonIcon>
            <IonLabel>
            {/* <Link to="/naehrwerte">Nährwerte & Auswertung</Link> */}
            Nährwerte & Auswertung
            </IonLabel>
          </IonItem>
          <IonItem button routerLink="/order">
            <IonIcon slot="start" icon={readerIcon}></IonIcon>
            <IonLabel>
            {/* <Link to="/order">Menü-Bestellungen</Link> */}
            Menü-Bestellungen
            </IonLabel>
          </IonItem></>

      )}

<IonItem  onClick={ () => { logout()}} button routerLink="/welcome">
      <IonIcon slot="start" color="danger" icon={returnIcon}></IonIcon>
      <IonLabel>
      Abmelden
      </IonLabel>
    </IonItem>

      </IonContent>
  </IonMenu>
    )}