import { useIonViewDidEnter,IonNote, IonButton, IonGrid, IonRow, IonCol, IonList, IonText, IonCardContent, IonCardHeader, IonIcon, IonCardSubtitle, IonCardTitle, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Order.css';

import fish from "../assets/svg_fish.png";
import beef from "../assets/svg_beef.png";
import vegan from "../assets/svg_vegan.png";
import chicken from "../assets/svg_chicken.png";
import pork from "../assets/svg_pork.png";
import lamb from "../assets/svg_lamb.png";

import restaurant from "../assets/restaurant.svg";
import info from "../assets/information-circle.svg";

import { useState, useEffect } from 'react';
import { Storage } from '@capacitor/storage';


  // const event = new Date();
  
  // let datenew = event.toISOString().split('T')[0].split('-');
  // datenew = datenew[0]+datenew[1]+datenew[2];
  

export default function Order(props) {

    const [selectedFood, setSelectedFood] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [dates, setDates] = useState(null)

    const [today, setToday] = useState(null)
    const [menuDates, setMenuDates] = useState([])

    const [cartData, setcartData] = useState(null);
    const [allergene, setAllergene] = useState([])
    const [modalAllergene, setModalAllergene] = useState(false)


    const [nutritionElement, setNutritionElement] = useState(null)
    const [nutritionModal, setNutritionModal] = useState(false)
    const [allergenListe, setAllergenListe] = useState(null)

    const [selectedDatum, setSelectedDatum] = useState(null)




    const checkStorage = async () => {

      const {value} = await Storage.get({ key: 'cart' })
      // console.log("value", value)
      if (value !== null) {setcartData(JSON.parse(value))}
      
      }

      
    async function addToCart(type, id){
        
      // console.log("addtoCart id",id)
      // console.log("addtoCart type",type)

      // console.log("addtoCart selectedFood",selectedFood)
      
      // console.log(selectedFood.findIndex(e=>e.id === id))
      type === 'plus' ? (selectedFood[selectedFood.findIndex(e=>e.id === id)].menge += 1) : (selectedFood[selectedFood.findIndex(e=>e.id === id)].menge -= 1)

      let tempCart = await Storage.get({ key: 'cart' });
      tempCart = JSON.parse(tempCart.value);
      console.log("tempcart",tempCart)

      let temp;

      tempCart&&tempCart.filter( (data, dataindex) => 
      
        data.menues.filter( (menu,menuindex) => { 
      
        menu.komponenten.forEach( (artikel, artikelindex) => {if ((artikel.id === id) && (data.datum.toString() === selectedDatum.toString()) )  { temp= {"d":dataindex, "m":menuindex, "a":artikelindex }} }  )   
      
      } 
      ))

      console.log("tempD",temp.d)
      
      // console.log("addtoCart tempCartkomp",tempCart[temp.d].menues[temp.m].komponenten)
      // console.log("FÜGE EIN")
      tempCart[temp.d].menues[temp.m].komponenten=[];
      selectedFood && console.log( tempCart[temp.d].menues[temp.m].komponenten=selectedFood)

      // console.log("EINGEFÜGT", tempCart)

      await Storage.set({ key: 'cart' , value: JSON.stringify(tempCart)})

    }

    const fetchAllergene = async () => {

      var requestOptions = {
        method: 'GET',
      };
  
      await fetch("https://web1.saint-online.de/funktionen/api/app/allergene_json.php", requestOptions)
        .then(response => response.json())
        .then(async (result) => {
         setAllergenListe(result.data[0])
  
        })
        .catch(error => console.log('error', error));
  
  
    }
    function getNow(){
      let event = new Date().toISOString().split('T')[0].split('-');
      let datenew = event[0]+event[1]+event[2];
      // console.log(datenew)
      setToday(datenew)
      }

    useEffect( () => {
      fetchAllergene();
      checkStorage();
      getNow();

      
    }, [])


    useIonViewDidEnter(() => {

      let event = new Date().toISOString().split('T')[0].split('-');
      let datenew = event[0]+event[1]+event[2];
      console.log(datenew)

      let element = document.getElementById(datenew)
      console.log(element)

      if (element !== null) {element.scrollIntoView({block: "start", behavior: "smooth"});
    // element.scrollBy(0,50)
  }

    })

    useEffect(() => {


      if (today !== null) {
  
        let tempDates = []
  
        for (let i = 0; i < 5; i++) {
          for (let x = 1; x < 4; x++) {
            tempDates.push((parseInt(today-1, 10) + i) + "," + x)
          }
        }
  
        setMenuDates(tempDates)
  
      }
    }, [today])

    useEffect( () => {

      if (cartData !== null)
      {
        // console.log("cartData", cartData)
      let tempData=cartData;
        let temp=[];
        tempData.map(e=> temp.push(e.datum))
        // console.log("TEMP", Array.from(new Set(temp)))
        setDates( Array.from(new Set(temp)) )
    }      

    },[cartData])

    useEffect( () => {

      
    //  dates && console.log(dates)
    

  },[dates])

  useEffect( () => {

      
    // selectedDatum && console.log(selectedDatum)
    

  },[selectedDatum])
    
    

    function showElements( menu,datum ) { 
  // console.log("showelements")

      // console.log("SELECTED FOOD",menu)
      setSelectedDatum(datum)
      setSelectedFood(menu)
      setIsOpen(true); 

    }

    function showNutrition(element) {
      setNutritionElement(element);
      setNutritionModal(true);
    }

      function showAllergene(zeugs) {

        console.log("ZEUGS",zeugs)
        let elemente=[];

        // console.log(zeugs.toString())

        if (zeugs.toString().search(",") !== -1) {

          
          elemente = zeugs.toString().split(',');
          // console.log(elemente)

        }

      else {

        elemente.push(zeugs.toString());      
        console.log(elemente)
      }
      
        
        setAllergene(elemente)
        setModalAllergene(true)
        
      }

    function formatDate(string){ 

      let year=(string.substr(0,4));
      let month=(string.substr(4,2));
      let day=(string.substr(6,2));
    
      const date = new Date(year, month, day-3);
    
      let wochentag = 
      date.toLocaleDateString('de-DE', {
        weekday: 'long',
      })
    
      return `${wochentag} - ${day}.${month}.${year}`
     }

    const showMenues = (wert) => {

      wert=wert.split(",")
      // console.log("SHOWMENUES WERT",wert)
      let mahlzeit = Number(wert[1]);
      let datum = wert[0];


       let tempCart;
      cartData && (tempCart = cartData)

      

      // for (let mahlzeit=1; mahlzeit<3; mahlzeit++){

      
      tempCart = tempCart.filter(e=>(e.mahlzeit.toString()===mahlzeit.toString()))
      // console.log(datum)
      
      tempCart = tempCart.filter(e=>(e.datum.toString() === datum.toString()))
  
      // console.log("SHOWMENUES TEMPCART", tempCart)

      // console.log(parseInt(tempCart[0].datum,10))
      // console.log(parseInt(today))


      return (

        (tempCart.length > 0) ?

        ( parseInt(tempCart[0].datum,10) >= parseInt(today,10) ) ?

        (
            // <IonCard class="karte" id={`${tempCart[0].datum}-${tempCart[0].mahlzeit}`} key={`${tempCart[0].datum}_${tempCart[0].mahlzeit}`}>
            <>
            <div style={{height: '50px'}} id={`${tempCart[0].datum}`}></div>
            <IonCard class="karte" key={`${tempCart[0].datum}_${tempCart[0].mahlzeit}`}>          
        <IonCardHeader>
<IonCardTitle><div>{ mahlzeit === 1 ? 'Frühstück' : mahlzeit === 2 ? 'Mittagessen' : 'Abendessen'}</div></IonCardTitle>
<IonCardSubtitle>{formatDate(datum.toString())}</IonCardSubtitle>
</IonCardHeader>
<IonCardContent>
{tempCart.map((flexbox, i) => {
        return (

          
          <div
          key={`${i}_${mahlzeit}_panel`}
            className={`flexbox-panel ${
              flexbox.menues.length > 1 ? "multiple" : "single"
            }`}
          >
            {flexbox.menues.map((item, index) => {
              return (
                <div key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_a`} className="flexbox-item" onClick={ () => showElements(item.komponenten, datum)} style={{ background: `url('${item.img}') no-repeat center center`, backgroundSize: 'cover'}}>
                  <div key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_ol_container`} className="overlay-container">
                    <div key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_top`}  className={item.komponenten.every((e) => e.premium === true) ? 'overlay-item-top bg2' : ''}>
                      <div key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_picto`} className="pikto">
                        {item.komponenten.some((e) => e.premium === false) ? (
                          ""
                        ) : (
                          <IonText key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_premium`}color="warning" class="premium">Premium</IonText>
                        )}
                         {item.komponenten.map((e,id) =>

                        (item.komponenten.length === 1) ? e.code.map( piktogramm => 
                             (piktogramm === "fish") ? (
                                <img key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_${id}_fisch`} src={fish} alt={e} />
                              ) : (piktogramm === "beef") ? (
                                <img key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_${id}_beef`} src={beef} alt={e} />
                              ) : (piktogramm === "lamb") ? (
                                <img key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_${id}_lamb`} src={lamb} alt={e} />
                              ) : (piktogramm === "chicken") ? (
                                <img key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_${id}_chicken`} src={chicken} alt={e} />
                              ) : (piktogramm === "pork") ? (
                                <img key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_${id}_pork`} src={pork} alt={e} />
                              ) :(piktogramm === "vegan") ? (
                                <img key={`${i}_${flexbox.datum}_${flexbox.mahlzeit}_${index}_${id}_vegan`} src={vegan} alt={e} />
                              ) : ("")) 
                              : ''
                         )}
                      </div>
                    </div>
                    <div className="overlay-item-bottom bg1">{item.name}</div>
                  </div>
                </div>
              );
            }
            )}
          </div>
        );
      }
      )}
  </IonCardContent>
  </IonCard></>  )  : 
                              
                              <>
                                <IonCard  style={{background: 'lightgrey'}} key={`${datum}_${mahlzeit}`}>
                                  <IonCardHeader>
                                    <IonCardTitle><div>{mahlzeit === 1 ? 'Frühstück' : mahlzeit === 2 ? 'Mittagessen' : 'Abendessen'}</div></IonCardTitle>
                                    <IonCardSubtitle>{formatDate(datum.toString())}</IonCardSubtitle>
                                  </IonCardHeader>
                        
                                  {tempCart.map((e) => e.menues.map((menu, menuindex) => menu.komponenten.map(komp => {
                                    if (komp.menge > 0) {
                        
                        
                        
                                      return (
                        
                                        <>
                                          <IonItem 
                                          className="ion-text-wrap" lines="none" key={`${komp.id}_${komp.bezeichnung}}`}>
                                            <IonLabel className="ion-text-wrap" lines="none"><h2>{komp.bezeichnung}<IonIcon slot="start"  onClick={() => showAllergene(komp.zusatzstoffe)} color="primary" icon={info}></IonIcon>{ ( (komp.code !== '') && (komp.code.length > 0 )) ? <img src={'https://web1.saint-online.de/test/svg_'+komp.code+'.png'} alt="mealcode" /> : ''}</h2></IonLabel>
                        
                                          
                        
                                            <IonNote text-wrap lines="none" slot="start">{(komp.img) ? <img style={{ height: '30px', width: '30px', borderRadius: '5px', alignSelf: 'bottom' }} src={komp.img} alt="img" /> : <img src={restaurant} style={{ height: '40px', marginRight: '15px', alignSelf: 'bottom' }} alt="placeholder" />}</IonNote>
                        
                                          </IonItem>
                                          <IonGrid>
                                    <IonRow>
                                      <IonCol  className="alignbottom"><div onClick={() => showNutrition(komp)}><h6>Nährwerte anzeigen <img src={info} alt="info" style={{height: '15px'}}/></h6></div></IonCol>
                                    </IonRow>
                                  </IonGrid>
                        
                                           
                                               
                                          <IonGrid style={{background: 'lightgrey'}} color="light">
                                          <IonRow>
                                            <IonCol  className="aligncenter" size="6" style={{alignVertical: 'middle'}}>{komp.menge === 0 ?
                                                          <IonButton disabled size="default" onClick={() => { setSelectedDatum(e.datum); setSelectedFood(menu.komponenten); addToCart('plus', komp.id, komp, e.datum); props.passProp(true); }}>Bestellen</IonButton> :
                                                          (<>
                                                            {komp.menge === 0 ? '' : <IonButton disabled color={komp.premium ? 'warning' : 'primary'} onClick={() => {addToCart('minus', komp.id, komp, e.datum); props.passProp(true);  }} size="small">–</IonButton>}
                                                            
                                                            <span className='text'><input type="text" value={komp.menge} /></span>
                                                            <IonButton disabled size="small" color={komp.premium ? 'warning' : 'primary'} onClick={() => { addToCart('plus', komp.id, komp, e.datum); props.passProp(true);  }}>+</IonButton></>)}</IonCol>
                                            <IonCol className=" aligncenter right"><IonText color="primary">{komp.preis === 0 ? '' : "x " + parseFloat(komp.preis).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €"}</IonText></IonCol>
                                            <IonCol className=" aligncenter right"><IonText color="primary"><b>{komp.preis === 0 ? '' : (komp.menge * komp.preis).toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " €"}</b></IonText></IonCol>
                                          </IonRow>
                                          </IonGrid>
                        </>
                        
                                      )
                                    }
                                  })))}
                        
                                  <IonCardContent>
                                  </IonCardContent>
                                </IonCard>
                                </>
                              
                              : '' 
  
      )
    // }
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menü-Bestellung</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
   { (cartData && menuDates) && menuDates.map((e,menuDateIndex) => showMenues(e)) }

<IonModal
      key="artikel"
        isOpen={isOpen}
        // trigger="trigger-button"
        breakpoints={[0.1, 0.75, 1]}
        initialBreakpoint={0.75}
        onWillDismiss={() => {setIsOpen(false); 
          // console.log(selectedFood)
        }
        }
      >
        <IonContent>
         <IonButton onClick={() => {setIsOpen(false); }} expand="full" color="primary">Schließen</IonButton>

            <IonCard>
                    { selectedFood !== null && selectedFood.map( (e,i) => { return( 

                    < ><IonItem lines="none" key={`${i}_${e.id}_${e.bezeichnung}`}>
         
            <IonCard className="article ion-text-center" style={{ backgroundImage: `url(${e.img ? e.img : restaurant})`, backgroundSize: 'cover' }}>
            </IonCard>            

            <IonLabel className="ion-text-wrap">
              <h1>{e.premium ? <IonText color="warning">PREMIUM</IonText> : ''} {e.bezeichnung}<IonIcon slot="start"  onClick={() => showAllergene(e.zusatzstoffe)} color="primary" icon={info}></IonIcon>
              { ( (e.code !== null)) ? <img src={'https://web1.saint-online.de/test/svg_'+e.code+'.png'} alt="mealcode" /> : ''}</h1>
              
              
            </IonLabel>  
              
            <IonNote slot="end"></IonNote>              

           

          </IonItem>
          <IonGrid>
            <IonRow>
              <IonCol  className="alignbottom"><div onClick={() => showNutrition(e)}><h6>Nährwerte anzeigen <img src={info} alt="info" style={{height: '15px'}}/></h6></div></IonCol>
              <IonCol color="primary"  className="aligntop" size="4">
                
                <div className="preisartikel">{e.premium ? parseFloat(e.preis).toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" €" : ''}</div><div className="summeartikel"> { e.premium ? "TOTAL "+parseFloat(e.preis * e.menge).toLocaleString('de', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" €" : '' }  </div>           </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem color="secondary"><IonLabel></IonLabel>
          <IonNote slot="end">

            <div className="itemCounter">
          
            { e.menge === 0 ? 
            <IonButton  size="default" color={e.premium ? 'warning' : 'primary'} onClick={() => {addToCart('plus', e.id); props.passProp(true);}}>Bestellen</IonButton> : 
            (<>
            {e.menge === 0 ? '' : <IonButton color={e.premium ? 'warning' : 'primary'} onClick={() => {addToCart('minus', e.id); props.passProp(true);}} size="default">–</IonButton>  }
            <span className='text'><input readOnly type="text" value={e.menge}/></span><IonButton color={e.premium ? 'warning' : 'primary'} size="default"  onClick={() => {addToCart('plus', e.id); props.passProp(true);}}>+</IonButton></>)}
          
          </div>

            </IonNote>
            </IonItem></>
          ) } ) } 



            </IonCard>

            <IonCard>            

            </IonCard>
         <IonButton onClick={() => {setIsOpen(false); }} expand="full" color="primary">Schließen</IonButton>

        </IonContent>
      </IonModal>

      <IonModal
      key="allergene"

        isOpen={modalAllergene}
        // trigger="trigger-button"
        breakpoints={[0.1, 0.75, 1]}
        initialBreakpoint={0.75}
        onWillDismiss={() => {setModalAllergene(false); }}
      >
        <IonContent>
         <IonButton onClick={() => {setModalAllergene(false); }} expand="full" color="primary">Schließen</IonButton>
        <IonItem>
         
         <IonCard className="article ion-text-center" style={{ backgroundImage: `url('${info}')`, backgroundSize: 'cover' }}> </IonCard>      
              

         <IonLabel id="Lorem_Ipsum" className="ion-text-wrap">Legende zu Allergenen / Zusatzstoffen:</IonLabel> 
       </IonItem>

       <IonGrid>
         <IonRow>
           <IonCol size="12">
             <IonList>
               { (allergene) && allergene.map( (e,i)=> { return( 

                  <><IonItem key={`allergen_${i}_${e}`}>
                 <IonLabel>{allergenListe ? allergenListe[e] : 'Zusatzstoff / Allergen'}</IonLabel>
                 <IonNote style={{width: '40px'}} color="primary" slot="start">{e}</IonNote>
               </IonItem></>

               )})}
               


              
             </IonList>
           </IonCol>
         </IonRow>
       </IonGrid>
       <IonButton onClick={() => {setModalAllergene(false); }} expand="full" color="primary">Schließen</IonButton>

          </IonContent>
          </IonModal>

          <IonModal
      key="nutrition"

        isOpen={nutritionModal}
        breakpoints={[0.1, 0.75, 1]}
        initialBreakpoint={0.75}
        onWillDismiss={() => {setNutritionModal(false);  setNutritionElement(null)}}
      >
        <IonContent>
       <IonButton onClick={() => {setNutritionModal(false);  setNutritionElement(null) }} expand="full" color="primary">Schließen</IonButton>

        <IonItem>
         
         <IonCard className="article ion-text-center" style={{ backgroundImage: `url('${info}')`, backgroundSize: 'cover' }}> </IonCard>      
              

         <IonLabel id="Lorem_Ipsum">Nährwert-Angaben:</IonLabel> 
       </IonItem>
         
          <IonList> 
            
          <IonGrid>
            <IonRow>
              <IonCol size="12">  
              <IonItem>
                  <IonLabel color="primary">Kalorien</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.kcal)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g / { nutritionElement && Math.round(parseFloat(((nutritionElement.kcal)/100)*nutritionElement.g_pro_portion)*4.1868).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1})} kJ</h6>
                      
                        </IonNote>
              </IonItem>                       
              <IonItem>
                  <IonLabel color="primary">Fett</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.fett/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">gesättigte Fettsäuren</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.gesfett/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Kohlenhydrate</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.kh/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Zucker</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.zucker/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Eiweiss</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.eiweiss/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
              <IonItem>
                  <IonLabel color="primary">Salz</IonLabel>
                      <IonNote slot="start"><h6>{ nutritionElement && Math.round(parseFloat(((nutritionElement.salz/1000)/100)*nutritionElement.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</h6></IonNote>
              </IonItem>
               </IonCol>
              <IonCol></IonCol>
            </IonRow>
          </IonGrid>
          </IonList>
       <IonButton onClick={() => {setNutritionModal(false);  setNutritionElement(null) }} expand="full" color="primary">Schließen</IonButton>

          </IonContent>
          </IonModal>
      

      </IonContent>
    </IonPage>
  );
};

//  <h6><IonText color="primary" >kcal</IonText> {Math.round(parseFloat(((e.kcal)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } kcal / {Math.round(parseFloat(((e.kcal)/100)*e.g_pro_portion)*4.1868).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } kJ </h6>
//               <h6> <IonText color="primary">Fett</IonText> {Math.round(parseFloat(((e.fett/1000)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) }g <IonText color="primary" >ges. Fettsäuren</IonText> {Math.round(parseFloat(((e.gesfett/1000)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) }g</h6>
//               <h6><IonText color="primary">KH</IonText> {Math.round(parseFloat(((e.kh/1000)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g <IonText color="primary">Zucker</IonText> {Math.round(parseFloat(((e.zucker/1000)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) }g  </h6>
//               <h6><IonText><IonText color="primary">Eiweiss</IonText> {Math.round(parseFloat(((e.eiweiss/1000)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) }g <IonText color="primary">Salz</IonText> {Math.round(parseFloat(((e.salz/1000)/100)*e.g_pro_portion)).toLocaleString('de', {minimumFractionDigits: 1, maximumFractionDigits: 1}) } g</IonText></h6>